import { useEffect } from 'react';

import useNavigation from '@/hooks/useNavigation';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import authRoutes from '@/pages/auth/routes';
import learner from '@/pages/corporate/routes/learner';

// we have handled redirection in redirect.js, but it was breaking when signing in using SSO.
// need to investigate why it was breaking and remove this code.
const Index = () => {
  const user = useUserContext();
  const navigation = useNavigation();

  useEffect(() => {
    if (user.id) {
      navigation.replace(learner.Home(), { appendQuery: false });
      return;
    }

    navigation.replace(authRoutes.Login(), { appendQuery: false });
  }, [navigation, user.id]);

  return null;
};

export default Index;
